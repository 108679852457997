import { FC } from 'react'
import { NextPage } from 'next'

import { useTranslationContext } from '../context/TranslationContext'
import useDocumentTitle from '../hooks/useDocumentTitle'
import { DashboardLayout } from '../layouts/dashboard-layout'

import LemonImage from '../components/Images/LemonImage'

const Error404Component: FC = () => {
  const { translation } = useTranslationContext()

  useDocumentTitle(translation.pageTitles.Error)

  return (
    <div className='px-4'>
      <div className=''>
        <h2 className='display-0 text-primary my-6 text-center'>404</h2>
        <div className='flex justify-center'>
          <LemonImage
            width={200}
            height={200}
            alt='not found'
            src='/img/not_lemon_13.png'
            layout='fixed'
          />
          <div>
            <h2>お探しのページが見つかりません。</h2>
            <p>
              アクセスいただいたページは削除、移動、または現在利用できない可能性があります。
              <br />
              ご迷惑をおかけして申し訳ございません。
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const Error404: NextPage = () => {
  return (
    <DashboardLayout breadcrumbs={[{ text: '' }]}>
      <Error404Component />
    </DashboardLayout>
  )
}

export default Error404
