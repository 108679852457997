import { useEffect } from 'react'

const useDocumentTitle = (title: string, defaultTitle = 'Lemon Square') => {
  useEffect(() => {
    window.document.title = title ? `${title} | ${defaultTitle}` : defaultTitle

    return () => {
      window.document.title = defaultTitle
    }
  }, [title])
}

export default useDocumentTitle
